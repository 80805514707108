import React, { useState, useEffect } from 'react';
import '../styling/FeaturesBenefits.scss';
import Countdown from '../components/Countdown';
import Footer from "../components/Footer";

const Facilitators = () => {
  const [price, setPrice] = useState({ value: 0, target: 100000 });
  const [price2, setPrice2] = useState({ value: 0, target: 60000 });
  const [price3, setPrice3] = useState({ value: 0, target: 36000 });
  const [price4, setPrice4] = useState({ value: 0, target: 24000 });
  const [price5, setPrice5] = useState({ value: 0, target: 16000 });

  const updateCounter = (counterState, setCounterState, speed = 500) => {
    const target = counterState.target;
    let count = counterState.value;

    const increment = target / speed;
    
    if (count < target) {
      setCounterState({ ...counterState, value: Math.ceil(count + increment) });
    } else {
      setCounterState({ ...counterState, value: target });
    }
  };

  useEffect(() => {
    // Observer for when the price counter becomes visible on screen
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          updateCounter(price, setPrice);
          updateCounter(price2, setPrice2);
          updateCounter(price3, setPrice3);
          updateCounter(price4, setPrice4);
          updateCounter(price5, setPrice5);
        }
      });
    }, { threshold: 0.5 });

    const counters = document.querySelectorAll('.price-counter');
    counters.forEach(counter => {
      observer.observe(counter);
    });

    return () => {
      counters.forEach(counter => {
        observer.unobserve(counter);
      });
    };
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div style={{ flex: 1 }}>
        <div className="features-container">

          <div className="feature-section">
            <h2 className="section-title">Facilitators</h2>
            <div className="feature-content">
              <h3 style={{textAlign: 'center'}}>Empower Startups and Elevate Your Offerings with i2u.ai</h3>
              <p>Welcome to i2u.ai, where product and service providers play a crucial role in enhancing the startup ecosystem. As facilitators, you have the opportunity to collaborate with innovative startups, driving their success while expanding your own influence and capabilities. At i2u.ai, we recognize the pivotal role your expertise and resources play in shaping the future of technology and business.</p>
            
    
              <div className="highlight-yellow-text-container">
              <p className="highlight-yellow-text">
                By registering in the waitlist for 
                  <span className="highlight-orange"> ₹1</span> or 
                   <span className="highlight-orange"> ₹101</span>, you can get a minimum 
                    <span className="highlight-orange"> ₹16,000</span> worth Beginner Subscription for a year!
              </p>
              <p className="highlight-yellow-text">
              You can even ‘beat the process’ and get 
                <span className="highlight-orange"> ₹1,00,000</span> worth Pro Max Ultra subscription for a year by paying ‘a little extra’!
              </p>
            </div>


               {/* Welcome Section */}
      <div className="welcome-section">
        <h1>Unlocking the Power of AI for Your Business</h1>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          At i2u.ai, we are committed to empowering businesses and individuals to unlock the full potential of Artificial Intelligence (AI). Our mission is to provide a cutting-edge platform that enables users to harness the power of AI to drive innovation, growth, and success.
        </p>
      </div>
      <br />

      {/* Invitation Section */}
      <div className="invitation-section">
        <h2 style={{ color: '#000' }}>Exclusive Invitation: Join the Future of AI</h2>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          We are excited to invite you to join our revolutionary platform, which is now open for registration. As a valued partner, fan, or member of our community, we want to give you a sneak peek into our exclusive offers.
        </p>
      </div>
      <br />

      {/* Invitation Fees Section */}
      <div className="invitation-fees">
        <h2 style={{ color: '#000' }}>Registration Fees</h2>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          We have introduced a special pricing plan for our stakeholders. Our Minimum Registration fees started at the following amounts:
        </p>
        <ul style={{ fontSize: '17px', textAlign: 'justify' }}>
          <li>
            <span style={{ backgroundColor: 'yellow', padding: '0 5px' }}>
              Job Seekers, Professionals, Employees, Freelancers, and Individual Service Providers: ₹1
            </span>
          </li>
          <li>
            <span style={{ backgroundColor: 'yellow', padding: '0 5px' }}>
              Startups, Mentors, Enablers, Influencers, Facilitators, and Investors: ₹101
            </span>
          </li>
        </ul>
      </div>
      <br />

      {/* Exclusive Rewards for Early Adopters Section */}
      <section className="section">
        <h2 style={{ color: '#5D2F8E' }}>Exclusive Rewards for Early Adopters</h2>
        <p style={{ fontSize: '17px' }}>
          As a token of appreciation for being one of our early adopters, we are offering exclusive rewards to the first 10% of registrants. These rewards include:
        </p>

        <div className="reward-grid">
          {/* Reward Cards */}
          <div className="reward-card">
            <h3>The Earliest<br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '2em' }}>1%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Pro Max Ultra Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.6em', fontWeight: 'bold', color: 'red' }}>
              worth ₹100000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>2%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Professional Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹60000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>3%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Advanced Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹36000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>4%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Basic Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹24000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>90%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Beginner Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹16000/Year
            </p>
            <p>FREE for the first year</p>
          </div>
        </div>
      </section>

      {/* Exciting Process Section */}
      <div className="exciting-process">
        <h2 style={{ color: '#000' }}>Upgrade Your Experience</h2>
        <p style={{ fontSize: '17px' }}>
          For those who don't make the cut for the above exclusive rewards, we have a special exciting process planned. This will give you the chance to pay over and above the invitation registration fees to be the earliest to be the highest paying registrant for the day, including our top-tier Pro Max Ultra package. This process will be conducted in a fair and transparent manner, with the highest paying earliest registrant winning the subscription package, henceforth.
        </p>
      </div>

      {/* Register Now Section */}
      <div className="highlight-text-container">
        <a href="https://i2u.ai/fc.html" className="cta-button">Register Now</a>
      </div>

      {/* Additional Information Below the Button */}
      <div className="additional-info" style={{ textAlign: 'center', fontSize: '16px', marginTop: '-10px', color: '#333' }}>
        <p><strong>Don't miss out on this opportunity to unlock the power of AI for your business.</strong></p>
        <p><span style={{ fontStyle: 'italic' }}>Note:</span> The registration fees and rewards are subject to change based on the response. We encourage you to register as soon as possible to take advantage of the current offer.</p>
      </div>
      <br />
      <br />
              <h3>Core Features</h3>
              <h4>Unmatched Market Intelligence</h4>
              <p>Equip yourself with i2u.ai’s advanced market intelligence tools to stay informed about emerging trends and industry shifts. Our platform offers valuable data and insights that can refine your offerings and strategies, ensuring you align with startup needs and market opportunities. By understanding industry dynamics, you position yourself to deliver solutions that propel startups forward.</p>

              <h4>AI-Driven Collaboration</h4>
              <p>Artificial intelligence is revolutionizing industries, and your products and services can be at the heart of this transformation. i2u.ai connects you with startups that are pioneering AI solutions, allowing you to provide the necessary support, whether through technology, services, or expertise, to accelerate their innovation journey and enhance mutual growth.</p>

              <h4>Engaged Mentorship and Networking</h4>
              <p>Join our thriving community of mentors, entrepreneurs, investors, and other industry experts. i2u.ai facilitates connections and collaborations that amplify your impact and extend your reach within the ecosystem. By engaging with this network, you can share valuable insights and participate in high-impact partnerships.</p>

              <h4>Integral Role in Demo Day Events</h4>
              <p>Participate actively in our Demo Day Platform, where startups present to investors and industry leaders. Your involvement can enhance their presentations and strategies, ensuring their innovations gain the recognition and support needed from the investment community.</p>

              <h3>Key Advantages</h3>
              <h4>Enhance Startup Collaboration</h4>
              <p>Engage with a pipeline of innovative startups eager to collaborate with experienced product and service providers. i2u.ai connects you with ventures that can benefit from your offerings, creating opportunities for impactful partnerships and mutual growth.</p>

              <h4>Reduce Market Entry Risks</h4>
              <p>Leverage i2u.ai’s resources and community expertise to minimize risks associated with introducing products and services to new markets. Our platform prepares startups for collaboration, ensuring they are resilient and well-supported in the competitive landscape.</p>

              <h4>Diversify Your Client Base</h4>
              <p>Broaden your reach by engaging with a diverse array of startups innovating across various sectors. i2u.ai provides access to ventures leveraging AI and cutting-edge technologies, enhancing your portfolio and broadening your potential client base.</p>

              <h4>Amplify Impact Through Collaborative Innovation</h4>
              <p>Position your products and services as enablers of transformative change. At i2u.ai, join forces with startups creating sustainable, AI-driven solutions that revolutionize industries, ensuring your contributions have significant and lasting impacts.</p>

              <h3>Benefits for Facilitators</h3>
              <h4>Leverage Strategic Insights</h4>
              <p>Utilize i2u.ai's insights to align your offerings with market needs. Our data-driven approach ensures you deliver solutions that resonate with startups’ strategic goals, maximizing the value and impact of your collaboration.</p>

              <h4>Directly Influence Technological Growth</h4>
              <p>Play a key role in the growth of future technologies by collaborating with innovative startups. Your products and services become integral to the development of AI applications and solutions, shaping the future of industries.</p>

              <h4>Expand Your Professional Network</h4>
              <p>Engage with a global community of forward-thinking entrepreneurs, investors, and mentors. By participating in i2u.ai, you expand your professional connections, unlock collaboration opportunities, and enhance your influence within the startup ecosystem.</p>

              <h4>Capitalize on AI-Enabled Expansion</h4>
              <p>Align with startups that leverage AI for growth and innovation. i2u.ai focuses on ventures that utilize AI to boost efficiencies and market reach, ensuring your collaborations are at the forefront of technological advancement.</p>

              <h4>Support Sustainable and Impactful Enterprises</h4>
              <p>Collaborate with startups committed to sustainability and impact. By providing the tools and services they need, you contribute to a future where technology is a positive force for communities and businesses alike.</p>

              <h4>Stay Adaptive to Industry Trends</h4>
              <p>Stay ahead of emerging trends and shifts in the technological landscape by being part of the i2u.ai network. Continuous engagement with our platform ensures you adapt quickly to new ideas and opportunities in the market.</p>

              <h3>Join Us at i2u.ai</h3>
              <p>Contribute to the development of tomorrow's industry leaders by joining i2u.ai. Partner with startups to provide the products and services essential for their success while expanding your own reach and influence in the market. Together, we can drive the evolution of innovative ideas into industry-defining solutions. Embrace this unique opportunity to impact the world of entrepreneurship and technology. Partner with i2u.ai today, where your expertise and offerings empower the next generation of innovators and deliver exceptional growth and potential.</p>
            </div>
          </div>

          {/* Price Information */}
          {/* <div className="price-highlight" onClick={() => window.location.href = 'https://i2u.ai/registration.html'}>
            <h2>Our Inaugural Invitation Price of ₹20,000 for Startups' Basic Annual Subscription</h2>
            <h3>is Going to be Raised to {price}!</h3>
            <p>Hurry! Our Inaugural Invitation Price Offer Closes on 1st January 2025 at 11:59 AM IST.</p>
            <h2 >Click Here to Subscribe!!</h2>
            <Countdown targetDate={targetDate} />
          </div> */}

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Facilitators;
