import React from 'react';
import '../styling/FeaturesBenefits.scss';
import Countdown from './Countdown';

const FeaturesBenefits = ({price}) => {
  const targetDate = '2025-01-01T11:59:00+05:30';
  price = price.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return (
    <div className="features-container">
      <div className="feature-section">
        <h2 className="section-title">Startups</h2>
        <div className="feature-content">
          <h3 style={{textAlign: 'center'}}>Empower your ideas with AI at i2u.ai and turn them into Unicorns.</h3>
          <p>Welcome to i2u.ai – the ultimate destination for startups ready to elevate their ventures to new heights. Our platform combines the power of artificial intelligence with comprehensive support, designed specifically for forward-thinking entrepreneurs. Whether you're just starting out or looking to refine your strategy, i2u.ai offers unparalleled resources, guidance, and a community to help you succeed.</p>
        
          <div className="highlight-yellow-text-container">
            <p className="highlight-yellow-text">
    By registering in the waitlist for 
    <span className="highlight-orange"> ₹1</span> or 
    <span className="highlight-orange"> ₹101</span>, you can get a minimum 
    <span className="highlight-orange"> ₹16,000</span> worth Beginner Subscription for a year!
  </p>
            <p className="highlight-yellow-text">
    You can even ‘beat the process’ and get 
    <span className="highlight-orange"> ₹1,00,000</span> worth Pro Max Ultra subscription for a year by paying ‘a little extra’!
  </p>
</div>


         {/* Welcome Section */}
      <div className="welcome-section">
        <h1>Unlocking the Power of AI for Your Business</h1>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          At i2u.ai, we are committed to empowering businesses and individuals to unlock the full potential of Artificial Intelligence (AI). Our mission is to provide a cutting-edge platform that enables users to harness the power of AI to drive innovation, growth, and success.
        </p>
      </div>
      <br />

      
         {/* Invitation Section */}
         <div className="invitation-section">
        <h2 style={{ color: '#000' }}>Exclusive Invitation: Join the Future of AI</h2>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          We are excited to invite you to join our revolutionary platform, which is now open for registration. As a valued partner, fan, or member of our community, we want to give you a sneak peek into our exclusive offers.
        </p>
      </div>
      <br />

      {/* Invitation Fees Section */}
      <div className="invitation-fees">
        <h2 style={{ color: '#000' }}>Registration Fees</h2>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          We have introduced a special pricing plan for our stakeholders. Our Minimum Registration fees started at the following amounts:
        </p>
        <ul style={{ fontSize: '17px', textAlign: 'justify' }}>
          <li>
            <span style={{ backgroundColor: 'yellow', padding: '0 5px' }}>
              Job Seekers, Professionals, Employees, Freelancers, and Individual Service Providers: ₹1
            </span>
          </li>
          <li>
            <span style={{ backgroundColor: 'yellow', padding: '0 5px' }}>
              Startups, Mentors, Enablers, Influencers, Facilitators, and Investors: ₹101
            </span>
          </li>
        </ul>
      </div>
      <br />

      {/* Exclusive Rewards for Early Adopters Section */}
      <section className="section">
        <h2 style={{ color: '#5D2F8E' }}>Exclusive Rewards for Early Adopters</h2>
        <p style={{ fontSize: '17px' }}>
          As a token of appreciation for being one of our early adopters, we are offering exclusive rewards to the first 10% of registrants. These rewards include:
        </p>

        <div className="reward-grid">
          {/* Reward Cards */}
          <div className="reward-card">
            <h3>The Earliest<br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '2em' }}>1%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Pro Max Ultra Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.6em', fontWeight: 'bold', color: 'red' }}>
              worth ₹100000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>2%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Professional Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹60000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>3%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Advanced Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹36000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>4%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Basic Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹24000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>90%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Beginner Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹16000/Year
            </p>
            <p>FREE for the first year</p>
          </div>
        </div>
      </section>
      
      {/* Exciting Process Section */}
      <div className="exciting-process">
        <h2 style={{ color: '#000' }}>Upgrade Your Experience</h2>
        <p style={{ fontSize: '17px' }}>
          For those who don't make the cut for the above exclusive rewards, we have a special exciting process planned. This will give you the chance to pay over and above the invitation registration fees to be the earliest to be the highest paying registrant for the day, including our top-tier Pro Max Ultra package. This process will be conducted in a fair and transparent manner, with the highest paying earliest registrant winning the subscription package, henceforth.
        </p>
      </div>

      {/* Register Now Section */}
      <div className="highlight-text-container">
        <a href="https://i2u.ai/s.html" className="cta-button">Register Now</a>
      </div>

      {/* Additional Information Below the Button */}
      <div className="additional-info" style={{ textAlign: 'center', fontSize: '16px', marginTop: '-10px', color: '#333' }}>
        <p><strong>Don't miss out on this opportunity to unlock the power of AI for your business.</strong></p>
        <p><span style={{ fontStyle: 'italic' }}>Note:</span> The registration fees and rewards are subject to change based on the response. We encourage you to register as soon as possible to take advantage of the current offer.</p>
      </div>
      <br />
      <br />
          
          <h3>Core Features</h3>
          <h4>Market Intelligence</h4>
          <p>Navigating the business landscape requires keen insights and the ability to anticipate shifts. At i2u.ai, we provide access to cutting-edge market insights and trend analysis that empower startups to identify untapped opportunities. Our robust intelligence tools analyze industry patterns, consumer behavior, and emerging trends, giving your startup the information it needs to stay ahead.</p>

          <h4>AI Expertise</h4>
          <p>AI is transforming industries across the globe, and understanding its complexities is crucial for any tech-driven business. Our platform delves deep into the world of AI, offering practical guidance for application and development. From machine learning to natural language processing, we ensure you have the expertise to harness AI's full potential, driving innovation within your startup.</p>

          <h4>Mentorship and Networking</h4>
          <p>A supportive network is one of the most valuable assets a startup can have. i2u.ai connects you with a thriving community of entrepreneurs, investors, and AI experts. Our mentorship program pairs you with seasoned professionals ready to share their wisdom and guide you through challenges. Networking opportunities abound, creating paths for collaboration and partnerships that can propel your startup forward.</p>

          <h4>Demo Day Platform</h4>
          <p>Showcase your startup on our Demo Day Platform, where you can present your innovations to a curated audience of investors and industry leaders. This is your chance to shine, demonstrating your product's potential and capturing the attention of those who can make a significant impact on your business trajectory.</p>

          <h3>Key Advantages</h3>
          <h4>Accelerated Growth</h4>
          <p>Speed is of the essence in the competitive startup landscape. i2u.ai equips your business with the tools and insights needed to gain a competitive edge. With our resources, your growth is accelerated, taking your startup from concept to reality faster and more efficiently.</p>

          <h4>Reduced Risk</h4>
          <p>Venturing into the AI domain can be daunting, but with i2u.ai, you are not alone. We help mitigate the challenges and complexities of navigating this landscape. Our expert guidance and comprehensive resources reduce potential risks, allowing you to focus on building and scaling your business.</p>

          <h4>Increased Investment Potential</h4>
          <p>Securing investment is often a critical milestone for startups. With i2u.ai, enhance your startup's attractiveness to investors. From providing you with the right visibility on our Demo Day Platform to refining your business model, we ensure you are investor-ready and poised for success.</p>

          <h4>Access to a Proven Network</h4>
          <p>Our platform offers direct access to an influential network of industry leaders and potential partners. This access is invaluable, opening doors to opportunities and collaborations that can drive growth, innovation, and market presence for your startup.</p>

          <h3>Benefits for Subscribers</h3>
          <h4>Uncover Hidden Opportunities</h4>
          <p>At i2u.ai, we help you identify market gaps and develop innovative solutions. By staying informed and prepared, you can seize opportunities that others may overlook, placing your startup at the forefront of industry advancements.</p>

          <h4>Build a Stronger Foundation</h4>
          <p>A solid understanding of AI and its applications is essential for modern startups. Through our resources and workshops, you gain a foundational knowledge of AI, ensuring you can make informed decisions and integrate AI effectively into your business operations.</p>

          <h4>Expand Your Network</h4>
          <p>Entrepreneurship thrives on connections. Our platform facilitates networking with like-minded entrepreneurs and potential collaborators, paving the way for partnerships that enhance your startup's reach and capabilities.</p>

          <h4>Accelerate Your Time to Market</h4>
          <p>Getting your product to market swiftly can make all the difference. Leverage our expert guidance to streamline your processes and accelerate your launch. With i2u.ai, you can navigate the journey from development to market entry with confidence and support.</p>

          <h4>Increase Your Funding Chances</h4>
          <p>Present your startup to targeted audiences through our Demo Day Platform. By demonstrating your startup's potential to a select group of investors, you maximize your chances of securing the necessary funding to propel your business forward.</p>

          <h4>Gain a Competitive Edge</h4>
          <p>In the fast-paced startup world, knowledge and resources are powerful allies. Stay ahead of the curve with i2u.ai's exclusive insights and resources, equipping your startup to meet challenges head-on and outpace the competition.</p>

          <h3>Join Us at i2u.ai</h3>
          <p>Embark on a transformative journey with i2uai, where your startup's potential meets unparalleled opportunity. Our commitment is to support your entrepreneurial spirit, providing the tools, community, and guidance needed to turn your vision into reality. Whether you're breaking ground in AI, exploring innovative markets, or seeking a competitive advantage, i2u.ai is your partner in success. Join our dynamic community today and unlock the full potential of your startup with i2u.ai – where ideas evolve into unicorns.</p>
        </div>
      </div>

      
     {/* Price Information */}
     <div className="price-highlight" onClick={() => window.location.href = 'https://i2u.ai/registration.html'}>
       <h2>Price of Single Basic Annual Subscription for Startups is ₹40,000</h2>
       <h2>
         <a href="https://i2u.ai/registration.html" className="subscribe-link">Click Here to Subscribe!!</a>
       </h2>
     </div>
      </div>
  );
};

export default FeaturesBenefits;
