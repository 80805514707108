import React from 'react';
import Modal from 'react-modal';
import '../styling/reservationModal.scss';

Modal.setAppElement('#root');

const ReservationModal = ({ isOpen, onClose }) => {
  const customStyles = {
    content: {
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      margin: '0', // Ensure no margins
      padding: '0', // Ensure no padding
      border: 'none', // Remove border
      borderRadius: '0', // Remove any border-radius
      backgroundColor: '#fff', // Background color
      overflow: 'hidden', // Hide overflow to prevent scrolling
      display: 'flex', // Flexbox for centering content
      justifyContent: 'center', // Center content horizontally
      alignItems: 'center', // Center content vertically
      zIndex: 1000, // Ensure it is on top
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)', // Dark background overlay
      zIndex: 999, // Ensure overlay is below the content
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Reservation Modal"
      shouldCloseOnOverlayClick={true}
      closeTimeoutMS={0} // Instant closing without transition
    >
      {/* Close Button */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
        <button onClick={onClose} style={{ fontSize: '30px', color: '#fff', background: 'transparent', border: 'none' }}>×</button>
      </div>

      {/* Fullscreen iframe */}
      <div style={{ height: '100%', width: '100%' }}>
        <iframe
          src="https://i2u.ai/s.html"
          width="100%"
          height="100%"
          title="Reservation Form"
          frameBorder="0"
          style={{ border: 'none' }}
        />
      </div>
    </Modal>
  );
};

export default ReservationModal;
