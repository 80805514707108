import React from 'react';
import Modal from 'react-modal';
import '../styling/reservationModal.scss';

Modal.setAppElement('#root');

const LoginModal = ({ isOpen, onClose }) => {
  const customStyles = {
    content: {
      top: '0',  // No margin from the top
      left: '0', // No margin from the left
      right: '0', // No margin from the right
      bottom: '0', // No margin from the bottom
      marginRight: '0', 
      marginLeft: '0',
      transform: 'none', // No translation needed
      width: '100%',  // Full width of the screen
      height: '100%', // Full height of the screen
      padding: '0', // Remove padding to maximize space
      border: 'none',
      borderRadius: '0', // Remove border-radius for full-screen effect
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column', // Ensure content and footer are stacked vertically
      overflow: 'auto', // Allow scrolling on mobile
      maxHeight: '100vh', // Set max height for better scrolling
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',  // Dark overlay with slight transparency
      zIndex: 1000, // Ensure modal is on top of other content
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Login Modal"
    >
      <div className="modal-header" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '30px' }}>
        <button onClick={onClose} className="close-button">×</button>
      </div>
      <div className="modal-content" style={{ flex: 1, overflow: 'hidden' }}>
        <iframe
          src="https://i2u.ai/access_account.html"
          width="100%"
          height="100%"
          title="Login Form"
          frameBorder="0"
          style={{ border: 'none', overflow: 'hidden' }} // Ensure no iframe overflow
        />
      </div>
      <div className="modal-footer" style={{ padding: '10px', backgroundColor: '#f1f1f1', textAlign: 'center' }}>
      </div>
    </Modal>
  );
};

export default LoginModal;
