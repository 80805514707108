import React from "react";
import { NavLink } from "react-router-dom";
import "../styling/footer.scss";
const CustomNavLink = ({ to, children, onClick }) => {
  return (
    <NavLink to={to} className={({ isActive }) => (isActive ? "active" : "")} onClick={onClick}>
      {children}
    </NavLink>
  );
};
    const Footer = () => {
      return (
        <footer>
          <div className="footer-container">
            {/* Sitemap */}
            <div className="footer-section">
              <h3 className="footer-title" style={{ color: 'black' }}>
                Sitemap
              </h3>
              <div className="footer-columns">
                {/* First Column */}
                <div className="footer-column">
                  <ul>
                    <li>
                      <a
                        href="https://react.i2u.ai/"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="/startups"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        Startups
                      </a>
                    </li>
                    <li>
                      <a
                        href="/jobseekers"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        Job Seekers
                      </a>
                    </li>
                    <li>
                      <a
                        href="/mentors"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        Mentors
                      </a>
                    </li>
                    <li>
                      <a
                        href="/enablers"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        Enablers
                      </a>
                    </li>
                    <li>
                      <a
                        href="/influencers"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        Influencers
                      </a>
                    </li>
                    <li>
                      <a
                        href="/facilitators"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        Facilitators
                      </a>
                    </li>
                    <li>
                      <a
                        href="/investors"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        Investors
                      </a>
                    </li>
                  </ul>
                </div>
    
                {/* Second Column with Border */}
                <div
                  className="footer-column"
                  style={{
                    border: '1px solid black',
                    padding: '10px',
                  }}
                >
                  <h4
                    className="bordered-title"
                    style={{
                      backgroundColor: 'black',
                      color: 'white',
                      padding: '10px',
                      margin: '0',
                    }}
                  >
                    Subscription Plans for:
                  </h4>
                  <table
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      marginTop: '0',
                    }}
                  >
                    <tr>
                      <td style={{ padding: '10px' }}>
                        <a
                          href="https://i2u.ai/s.html"
                          style={{ textDecoration: 'underline', color: 'blue' }}
                        >
                          Startups
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        <a
                          href="https://i2u.ai/j.html"
                          style={{ textDecoration: 'underline', color: 'blue' }}
                        >
                          Job Seekers
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        <a
                          href="https://i2u.ai/m.html"
                          style={{ textDecoration: 'underline', color: 'blue' }}
                        >
                          Mentors
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        <a
                          href="https://i2u.ai/en.html"
                          style={{ textDecoration: 'underline', color: 'blue' }}
                        >
                          Enablers
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        <a
                          href="https://i2u.ai/if.html"
                          style={{ textDecoration: 'underline', color: 'blue' }}
                        >
                          Influencers
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        <a
                          href="https://i2u.ai/fc.html"
                          style={{ textDecoration: 'underline', color: 'blue' }}
                        >
                          Facilitators
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        <a
                          href="https://i2u.ai/iv.html"
                          style={{ textDecoration: 'underline', color: 'blue' }}
                        >
                          Investors
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
    
                {/* Third Column */}
                <div className="footer-column">
                  <ul>
                    <li>
                      <a
                        href="https://i2u.ai/drpl/"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        i2u.ai Articles
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://ecosystem.i2u.ai/"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        i2u.ai Ecosystem
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://i2u.ai/contact.html"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
    
          <div className="footer">
            <div>&copy; 2025{" "}
              <a
                href="https://i2u.ai/"
                style={{ textDecoration: 'underline' }}
              >
                i2u.ai
              </a>
            </div>
    
            <div>
              <a
                href="https://i2u.ai/TermsNConditions/"
                style={{ textDecoration: 'underline' }}
              >
                Terms &amp; Conditions
              </a>{' '}
              |{' '}
              <a
                href="https://i2u.ai/drpl/RRSTC"
                target="_blank"
                style={{ textDecoration: 'underline' }}
              >
                Referral Reward System Terms &amp; Conditions
              </a>{' '}
              |{' '}
              <a
                href="https://i2u.ai/PrivacyPolicy/"
                style={{ textDecoration: 'underline' }}
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </footer>
      );
    };
    
    export default Footer;