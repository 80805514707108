import React, { useEffect, useRef } from "react";
import ImageSlider from "../components/ImageSlider";
import PostsClone from "../components/PostsClone";
import res1 from "../Assets/images/res1.jpg";
import res2 from "../Assets/images/res2.jpg";
import res3 from "../Assets/images/res3.jpg";
import data from "../Assets/data/data.json";
import "../styling/pageStyling.scss";
import HomepagePosts from "../components/HomepagePosts";
import Footer from "../components/Footer";

import sl1 from "../Assets/images/slider_image_1.jpeg";
import sl2 from "../Assets/images/slider_image_2.jpg";
import sl3 from "../Assets/images/slider_image_3.jpg";

import hp1 from '../Assets/images/post_images/hp1.jpg';
import hp2 from '../Assets/images/post_images/hp2.jpg';
import hp3 from '../Assets/images/post_images/hp3.jpg';

import st1 from '../Assets/images/post_images/st1.jpeg';
import st2 from '../Assets/images/post_images/st2.jpeg';
import st3 from '../Assets/images/post_images/st3.jpeg';
import st4 from '../Assets/images/post_images/st4.jpeg';
import st5 from '../Assets/images/post_images/st5.jpeg';

import m1 from '../Assets/images/post_images/m1.png';
import m2 from '../Assets/images/post_images/m2.png';
import m3 from '../Assets/images/post_images/m3.jpeg';
import m4 from '../Assets/images/post_images/m4.jpg';
import m5 from '../Assets/images/post_images/m5.jpg';

import e1 from '../Assets/images/post_images/e1.png';
import e2 from '../Assets/images/post_images/e2.png';
import e3 from '../Assets/images/post_images/e3.png';
import e4 from '../Assets/images/post_images/e4.png';
import e5 from '../Assets/images/post_images/e5.png';

function Homepage() {
  const images = [
    hp3,hp1,hp2,
    st3, st2, st4, st5, st1,
    m1, m2, m3, m4, m5,
    e1, e2, sl1, sl2, sl3
  ];

  const sliderImages = [
    {
      url: res1,
      title: "i2u.ai, Ideas through AI! Registration!",
      link: "https://i2u.ai/s.html",
      color:"#00008b",
      bgColor:"rgba(206, 206, 255,0.949)"
    },
    {
      url: res2,
      title: "i2u.ai, Ideas through AI! Registration!",
      link: "https://i2u.ai/s.html",
      color:"#d60084",
      bgColor:'rgba(248, 235, 255, 1)'
    },
    {
      url: res3,
      title: "i2u.ai, Ideas through AI! Registration!",
      link: "https://i2u.ai/s.html", 
      color:"#006400",
      bgColor:"rgba(149, 250, 236, 1)"
    },
  ];

  const updateCounter = (counter) => {
    const target = +counter.getAttribute('data-target');
    let count = +counter.innerText;
    const speed = 500;
    const increment = target / speed;

    if (count < target) {
      counter.innerText = Math.ceil(count + increment);
      setTimeout(() => updateCounter(counter), 10);
    } else {
      counter.innerText = target;
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        const counter = entry.target;
        if (entry.isIntersecting) {
          counter.innerText = '0'; 
          updateCounter(counter);
        }
      });
    }, { threshold: 0.5 });

    const counters = document.querySelectorAll('.price-counter');
    counters.forEach(counter => {
      observer.observe(counter);
    });

    return () => {
      counters.forEach(counter => {
        observer.unobserve(counter);
      });
    };
  }, []);

  return (
    <div className="home_container">
      <div className="welcome-container">
        <h1 className="main-header">
         Welcome to i2u.ai, Ideas to Unicorns through AI! <span className="sub-header"> Embracing the Future with Innovation, Passion & Wisdom to Seize the Opportunities!</span>
        </h1>
        <p>i2u.ai, Ideas to Unicorns through AI, warmly welcomes startups, job seekers, mentors, enablers, influencers, facilitators, investors, employees, professionals, … to explore a world of limitless potential.</p>
        <p>Our platform is designed to be your ultimate companion in harnessing the power of AI, fostering growth, and nurturing innovation. Whether you're on a quest to elevate your startup, expand your career, or connect with a vibrant community of forward-thinkers & doers, i2u.ai is here to support your journey every step of the way.</p>
        <p>Join us in revolutionizing industries and making your future the most successful one! Let's make your visions come alive!</p>
      </div>

      <div className="highlight-yellow-text-container">
  <p className="highlight-yellow-text">
    By registering in the waitlist for 
    <span className="highlight-orange"> ₹1</span> or 
    <span className="highlight-orange"> ₹101</span>, you can get a minimum 
    <span className="highlight-orange"> ₹16,000</span> worth Beginner Subscription for a year!
  </p>
  <p className="highlight-yellow-text">
    You can even ‘beat the process’ and get 
    <span className="highlight-orange"> ₹1,00,000</span> worth Pro Max Ultra subscription for a year by paying ‘a little extra’!
  </p>
</div>


         {/* Welcome Section */}
          <div className="welcome-section">
        <h1>Unlocking the Power of AI for Your Business</h1>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          At i2u.ai, we are committed to empowering businesses and individuals to unlock the full potential of Artificial Intelligence (AI). Our mission is to provide a cutting-edge platform that enables users to harness the power of AI to drive innovation, growth, and success.
        </p>
      </div>
      <br />

      {/* Invitation Section */}
      <div className="invitation-section">
        <h2 style={{ color: '#000' }}>Exclusive Invitation: Join the Future of AI</h2>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          We are excited to invite you to join our revolutionary platform, which is now open for registration. As a valued partner, fan, or member of our community, we want to give you a sneak peek into our exclusive offers.
        </p>
      </div>
      <br />
         {/* Invitation Fees Section */}
         <div className="invitation-fees">
        <h2 style={{ color: '#000' }}>Registration Fees</h2>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          We have introduced a special pricing plan for our stakeholders. Our Minimum Registration fees started at the following amounts:
        </p>
        <ul style={{ fontSize: '17px', textAlign: 'justify' }}>
          <li>
            <span style={{ backgroundColor: 'yellow', padding: '0 5px' }}>
               Job Seekers, Professionals, Employees, Freelancers, and Individual Service Providers: ₹1
            </span>
          </li>
          <li>
            <span style={{ backgroundColor: 'yellow', padding: '0 5px' }}>
              Startups, Mentors, Enablers, Influencers, Facilitators, and Investors: ₹101
            </span>
          </li>
        </ul>
      </div>
      <br />
      
      {/* Reward Cards Section */}
      <section className="section">
        <h2 style={{ color: '#5D2F8E' }}>Exclusive Rewards for Early Adopters</h2>
        <div className="reward-grid">
          {/* Reward Cards */}
          <div className="reward-card">
            <h3>The Earliest<br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '2em' }}>1%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Pro Max Ultra Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.6em', fontWeight: 'bold', color: 'red' }}>
              worth ₹<span className="price-counter" data-target="100000">0</span>/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>2%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Professional Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹<span className="price-counter" data-target="60000">0</span>/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>3%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Advanced Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹<span className="price-counter" data-target="36000">0</span>/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>4%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Basic Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹<span className="price-counter" data-target="24000">0</span>/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>90%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Beginner Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹<span className="price-counter" data-target="16000">0</span>/Year
            </p>
            <p>FREE for the first year</p>
          </div>
        </div>
      </section>
       {/* Exciting Process Section */}
       <div className="exciting-process">
        <h2 style={{ color: '#000' }}>Upgrade Your Experience</h2>
        <p style={{ fontSize: '17px' }}>
          For those who don't make the cut for the above exclusive rewards, we have a special exciting process planned. This will give you the chance to pay over and above the invitation registration fees to be the earliest to be the highest paying registrant for the day, including our top-tier Pro Max Ultra package. This process will be conducted in a fair and transparent manner, with the highest paying earliest registrant winning the subscription package, henceforth.
        </p>
      </div>

      {/* Register Now Section */}
      <div className="highlight-text-container">
        <a href="https://i2u.ai/s.html" className="cta-button">Register Now</a>
      </div>

      {/* Additional Information Below the Button */}
      <div className="additional-info" style={{ textAlign: 'center', fontSize: '16px', marginTop: '-10px', color: '#333' }}>
        <p><strong>Don't miss out on this opportunity to unlock the power of AI for your business.</strong></p>
        <p><span style={{ fontStyle: 'italic' }}>Note:</span> The registration fees and rewards are subject to change based on the response. We encourage you to register as soon as possible to take advantage of the current offer.</p>
      </div>
      <br />
      <br />
      {/* Slider and Footer */}
      <ImageSlider slides={sliderImages} />
      <HomepagePosts posts={data.homepage} images={images} />
      <Footer />
    </div>
  );
}

export default Homepage;
