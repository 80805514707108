import React from 'react';
import '../styling/FeaturesBenefits.scss';
import Countdown from './Countdown';

const MentorBenefits= ({price = 20000}) => {
  const targetDate = '2025-01-01T11:59:00+05:30';
  price = price.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
  return (
    <div className="features-container">
      <div className="feature-section">
        <h2 className="section-title">Mentors</h2>
        <div className="feature-content">
          <h3 style={{textAlign: 'center'}}>Empower the Next Generation of Innovators with i2u.ai</h3>
          <p>Welcome to i2u.ai, a platform where experience meets innovation and visionaries collaborate to create the future. At i2u.ai, we understand that mentorship is a cornerstone of entrepreneurial success. Your expertise and guidance can propel startups to new heights, helping them navigate the complexities of the business world and transform their ideas into industry-leading ventures.</p>
         
              
          <div className="highlight-yellow-text-container">
              <p className="highlight-yellow-text">
                By registering in the waitlist for 
                  <span className="highlight-orange"> ₹1</span> or 
                   <span className="highlight-orange"> ₹101</span>, you can get a minimum 
                    <span className="highlight-orange"> ₹16,000</span> worth Beginner Subscription for a year!
              </p>
              <p className="highlight-yellow-text">
              You can even ‘beat the process’ and get 
                <span className="highlight-orange"> ₹1,00,000</span> worth Pro Max Ultra subscription for a year by paying ‘a little extra’!
              </p>
            </div>
          
          {/* Welcome Section */}
      <div className="welcome-section">
        <h1>Unlocking the Power of AI for Your Business</h1>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          At i2u.ai, we are committed to empowering businesses and individuals to unlock the full potential of Artificial Intelligence (AI). Our mission is to provide a cutting-edge platform that enables users to harness the power of AI to drive innovation, growth, and success.
        </p>
      </div>
      <br />

         {/* Invitation Section */}
         <div className="invitation-section">
        <h2 style={{ color: '#000' }}>Exclusive Invitation: Join the Future of AI</h2>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          We are excited to invite you to join our revolutionary platform, which is now open for registration. As a valued partner, fan, or member of our community, we want to give you a sneak peek into our exclusive offers.
        </p>
      </div>
      <br />

      {/* Invitation Fees Section */}
      <div className="invitation-fees">
        <h2 style={{ color: '#000' }}>Registration Fees</h2>
        <p style={{ fontSize: '17px', textAlign: 'justify' }}>
          We have introduced a special pricing plan for our stakeholders. Our Minimum Registration fees started at the following amounts:
        </p>
        <ul style={{ fontSize: '17px', textAlign: 'justify' }}>
          <li>
            <span style={{ backgroundColor: 'yellow', padding: '0 5px' }}>
              Job Seekers, Professionals, Employees, Freelancers, and Individual Service Providers: ₹1
            </span>
          </li>
          <li>
            <span style={{ backgroundColor: 'yellow', padding: '0 5px' }}>
              Startups, Mentors, Enablers, Influencers, Facilitators, and Investors: ₹101
            </span>
          </li>
        </ul>
      </div>
      <br />

      {/* Exclusive Rewards for Early Adopters Section */}
      <section className="section">
        <h2 style={{ color: '#5D2F8E' }}>Exclusive Rewards for Early Adopters</h2>
        <p style={{ fontSize: '17px' }}>
          As a token of appreciation for being one of our early adopters, we are offering exclusive rewards to the first 10% of registrants. These rewards include:
        </p>

        <div className="reward-grid">
          {/* Reward Cards */}
          <div className="reward-card">
            <h3>The Earliest<br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '2em' }}>1%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Pro Max Ultra Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.6em', fontWeight: 'bold', color: 'red' }}>
              worth ₹100000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>2%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Professional Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹60000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>3%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Advanced Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹36000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>4%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Basic Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹24000/Year
            </p>
            <p>FREE for the first year</p>
          </div>

          <div className="reward-card">
            <h3>The Next <br /><span style={{ fontWeight: 'bold', color: '#3498db', fontSize: '1.3em' }}>90%<br /></span>Registrants* will get <br />
              <span style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#000' }}>Beginner Subscription</span>
            </h3>
            <p className="price" style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'red' }}>
              worth ₹16000/Year
            </p>
            <p>FREE for the first year</p>
          </div>
        </div>
      </section>

      {/* Exciting Process Section */}
      <div className="exciting-process">
        <h2 style={{ color: '#000' }}>Upgrade Your Experience</h2>
        <p style={{ fontSize: '17px' }}>
          For those who don't make the cut for the above exclusive rewards, we have a special exciting process planned. This will give you the chance to pay over and above the invitation registration fees to be the earliest to be the highest paying registrant for the day, including our top-tier Pro Max Ultra package. This process will be conducted in a fair and transparent manner, with the highest paying earliest registrant winning the subscription package, henceforth.
        </p>
      </div>

      {/* Register Now Section */}
      <div className="highlight-text-container">
        <a href="https://i2u.ai/m.html" className="cta-button">Register Now</a>
      </div>

      {/* Additional Information Below the Button */}
      <div className="additional-info" style={{ textAlign: 'center', fontSize: '16px', marginTop: '-10px', color: '#333' }}>
        <p><strong>Don't miss out on this opportunity to unlock the power of AI for your business.</strong></p>
        <p><span style={{ fontStyle: 'italic' }}>Note:</span> The registration fees and rewards are subject to change based on the response. We encourage you to register as soon as possible to take advantage of the current offer.</p>
      </div>
      <br />
      <br />
          <h3>Core Features</h3>
          <h4>Market Intelligence</h4>
          <p>As a mentor with i2u.ai, you gain access to state-of-the-art market intelligence tools that enable you to offer insightful guidance. Our platform provides comprehensive market insights and trend analyses, arming you with the data necessary to help startups identify and seize untapped opportunities. By offering your mentees this level of foresight, you can guide them to make strategic decisions that set their ideas apart.</p>

          <h4>AI Expertise</h4>
          <p>Artificial intelligence is at the heart of transformative innovation, and your experience in this space is vital. i2u.ai delves deep into AI, providing resources and support to enhance your mentorship. Whether your focus is on the nuances of AI application or development, our platform equips you with the knowledge and tools needed to guide startups as they harness AI's potential, driving innovation and growth.</p>

          <h4>Mentorship and Networking</h4>
          <p>Join a thriving community of fellow mentors, entrepreneurs, investors, and AI experts at i2u.ai. Our platform fosters meaningful connections and facilitates the sharing of expertise and inspiration. By integrating into this network, you have the opportunity to expand your influence, collaborate with other leaders, and contribute to a culture of innovation and success.</p>

          <h4>Demo Day Involvement</h4>
          <p>As a mentor, you play a pivotal role in preparing startups for our Demo Day Platform. This is where entrepreneurs present their innovations to a select audience of investors and industry leaders. Your mentorship can help refine their strategies, enhance their presentations, and improve their chances of capturing the attention and investment they need to thrive.</p>

          <h3>Key Advantages</h3>
          <h4>Influence Startups' Accelerated Growth</h4>
          <p>Empower startups to achieve rapid growth by sharing your insights and experience. With your support, they can leverage i2u.ai's resources to transform concepts into reality quickly and efficiently, benefitting from your guidance every step of the way.</p>

          <h4>Minimize Risk for Mentees</h4>
          <p>Help startups navigate the AI landscape's challenges with your expertise, reducing potential risks and obstacles. Your mentorship enables entrepreneurs to make informed decisions, fostering confidence and resilience in a competitive market.</p>

          <h4>Enhance Startup Investment Potential</h4>
          <p>Your mentorship can significantly boost a startup’s investment appeal. By guiding them to refine their business models and strategies, you enhance their readiness to engage with investors and capitalize on opportunities presented on our Demo Day Platform.</p>

          <h4>Access to an Active Network</h4>
          <p>Join a network of industry leaders and potential collaborators. Your involvement in i2u.ai not only broadens your professional contacts but also opens up strategic opportunities for collaboration, enabling you to contribute to cutting-edge projects and initiatives.</p>

          <h3>Benefits for Mentors</h3>
          <h4>Identify and Cultivate Potential</h4>
          <p>With i2u.ai's intelligence tools, you can identify promising startups and help them uncover hidden opportunities. Your mentorship is pivotal in guiding them to develop innovative solutions and navigate market gaps.</p>

          <h4>Contribute to a Stronger AI Ecosystem</h4>
          <p>Through your efforts, help build a stronger AI foundation within startups, ensuring they understand and effectively integrate AI into their operations. Your influence deepens the sector’s knowledge base and supports the creation of sustainable, AI-powered solutions.</p>

          <h4>Expand Your Professional Network</h4>
          <p>Engage with a diverse array of entrepreneurs and industry leaders. By contributing to our community, you strengthen your professional connections and gain access to new opportunities for collaboration and innovation.</p>

          <h4>Accelerate Innovations to Market</h4>
          <p>Play a key role in driving startups from development to market. Your mentorship accelerates their journey, utilizing your insights and the platform's resources to streamline processes, optimize timing, and enhance market readiness.</p>

          <h4>Strengthen the Startup Investment Pipeline</h4>
          <p>Help startups maximize their funding potential by preparing them for targeted investor audiences. Your guidance ensures they present compelling, well-rounded cases that attract the necessary investment to fuel their growth.</p>

          <h4>Maintain a Competitive Edge</h4>
          <p>Stay informed and engaged with the latest trends and innovations by remaining active within i2u.ai's ecosystem. This engagement not only keeps you at the forefront of industry advancements but also empowers your mentees to remain competitive.</p>

          <h3>Join Us at i2u.ai</h3>
          <p>Your expertise and dedication can be a transformative force for entrepreneurs poised to make significant impacts in their industries. Join i2u.ai and become part of a community that values mentorship, innovation, and growth. Together, we can inspire and equip the next generation of leaders, ensuring their journeys from ideas to unicorns are supported and successful. Embrace this unique opportunity to influence and shape the future of tech startups at i2u.ai. Share your knowledge, expand your horizons, and leave a lasting impact on emerging innovators. Join our dynamic and inspiring community today, where your mentorship fuels new possibilities.</p>
        </div>
      </div>
      {/* Price Information */}
      <div className="price-highlight" onClick={() => window.location.href = 'https://i2u.ai/registration.html'}>
        <h2>Price of Single Basic Annual Subscription for Mentors is ₹50,000</h2>
        <h2>
         <a href="https://i2u.ai/registration.html" className="subscribe-link">Click Here to Subscribe!!</a>
       </h2>
      </div>

    </div>
  );
};

export default MentorBenefits;
